<template>
  <p
    :class="{ 'disable, pointer-none': loading }"
    @click="downloadPdfbyItem(item)"
    class="d-flex cursor-pointer justify-content-center align-items-end m-0 p-0 pb-1"
  >
    <ph-arrow-line-down :size="20" color="#EE7F00" />
    <u class="ms-1">{{ loading ? "Loading.." : "Download" }}</u>
  </p>
</template>
<script>
import {
  PhArrowLineDown
} from 'phosphor-vue'
import axios from 'axios'
import pdfHelper from '../../libs/pdf'
import fileDownload from 'js-file-download'
import { mapGetters } from 'vuex'
export default {
  name: 'PdfDownload',
  components: {
    PhArrowLineDown
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    },
    isInterliving: {
      type: Boolean,
      default: false
    },
    isTrendhopper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getAccessToken'])
  },
  methods: {
    async downloadPdfbyItem (par) {
      this.loading = true
      try {
        let response = null
        if (this.isInterliving || this.isTrendhopper) {
          response = await axios.get(`${config.VUE_APP_BASE_URL || process.env.VUE_APP_BASE_URL}/${this.isTrendhopper ? 'trendhopper' : 'interliving'}-uvps/supplier/${par.supplierId}/model/${par.modelId}/download`, {
            headers: { Authorization: 'Bearer ' + this.getAccessToken }
          })
          const pdfbuffer = await pdfHelper.downloadPdf(response.data.file)
          fileDownload(pdfbuffer, `${this.item.name}.pdf`)
        } else {
          let data = {
            tz: par.tz,
            step: par.stepValue,
            status: par.status,
            validFrom: par.validFrom,
            validTill: par.validTill
          }
          if (par.zusatznr) data = { ...data, zusatznr: par.zusatznr }
          response = await axios.post(
            `${config.VUE_APP_BASE_URL || process.env.VUE_APP_BASE_URL}/catalogue/supplier/${par.supplierId}/model/${this.item.modelId}/download`,
            data,
            { headers: { Authorization: 'Bearer ' + this.getAccessToken } }
          )

          let filename = `${this.name}_${par.tz ? 'TZ' : ''}_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${par.modelId}_${par.stepName}`
          if (par.zusatznr) {
            filename += `_v${par.zusatznr}`
          }
          if (par.zusatz) {
            filename += `_${par.zusatz}`
          }
          const coversheetBuffer = Buffer.from(response.data.coversheet)
          const catalogueBuffer = await pdfHelper.downloadPdf(response.data.file)
          const buffer = await pdfHelper.mergeCoversheet(coversheetBuffer, catalogueBuffer)
          fileDownload(buffer, `${filename}.pdf`)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
